body {
  overflow: hidden;
  min-width: fit-content;
}

.navbar {
  padding-left: 3%;
  padding-right: 2%;
  padding-bottom: 1%;
  padding-top: 2vh;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 7;
  width: 100%;
  position: sticky;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.logo {
  margin-left: 2%;
  width: 47vw;
  max-width: 470px;
}

.menuBar {
  display: flex;
  justify-content: space-between;
  width: 48vw;
  max-width: 700px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-width: 1.5px;
  border-color: rgba(255, 255, 255, 0.7);
  border-style: solid;
  white-space: nowrap;
  padding-left: 1%;
  padding-right: 1%;
}
#link {
  margin-bottom: 5px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0.2);
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  border-radius: 5%;
  transition: transform .2s;
}
#link:hover {
  transform: scale(1.10);
  color: rgb(235, 235, 235);
}

