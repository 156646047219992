body {
  overflow: auto;
}

.header {
  width: 100%;
  text-align: center;
  margin-top: 6%;
  border-top: 00;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.8);
  border-bottom-width: 1px;
}
.header h1 {
  font-family: "TimesNewRoman";
  padding-bottom: 2%;
  font-weight: 200;
  word-spacing: 8%;
  letter-spacing: 1px;
  color: white;
}

.SoMe {
  border-style: solid;
  border-color: rgb(255, 255, 255);
  border-right: 0;
  border-left: 0;
  border-bottom: 0;
  border-width: 1px;
  display: flex;
  justify-content: space-around;
  width: 80%;
  padding-top: 7%;
  padding-bottom: 7%;
}
#SoMeIcon {
  max-width: 90px;
  width: 7vh;
  transition: transform .2s;
}
#SoMeIcon:hover {
  transform: scale(1.2);
}
.form-group {
  width: 75%;
  max-width: 600px;
  align-items: center;
  text-align: center;
  padding-bottom: 4%;
  justify-content: space-between;
}
#contact-form {
  margin-top: 4%;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 42vh;
  display: flex;
}

#input {
  resize: none;
  outline: none;
  font-size: 17px;
  font-family: 'Times New Roman';
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  width: 74%;
  padding: 2%;
  border-radius: 0px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-width: 1px;
  border-color: rgb(127, 127, 127);
  transition: transform .2s;
}
#input:focus {
  background-color: rgba(7, 7, 7, 0.7);
  border-width: 2px;
  transform: scale(1.02);
}

.dateField {
  width: 77%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
#date {
  resize: none;
  outline: none;
  font-size: 17px;
  font-family: 'Times New Roman';
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  width: 22%;
  margin: 0% 2%;
  padding: 2%;
  border-radius: 0px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-width: 1px;
  border-color: rgb(127, 127, 127);
  transition: transform .2s;
  text-align: center;
  align-items: center;
  justify-content: center;
}
#date:focus {
  background-color: rgba(7, 7, 7, 0.7);
  border-width: 2px;
  transform: scale(1.02);
}

.btn {
  color: black;
  background-color: white;
  border-radius: 4%;
  border: 0;
  padding: 1.4vh;
  width: 40%;
  max-width: 25ch;
  transition: transform .2s;
}
.btn:hover {
  transform: scale(1.17);
}
.btn:disabled {
  color: rgb(0, 0, 0);
  background-color: rgb(89, 89, 89);
}
.btn:disabled:hover {
  cursor: not-allowed;
  transform: scale(1);
}
.loader {
  margin-top: 0.1vh;
  bottom: 0;
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}