body {
  overflow: hidden;
}

video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%; 
  min-height: 100%;
}

@media only screen and (max-width: 1400px) {
  .video {
    overflow: hidden;
    position: absolute;
    right: 0;
    margin-top: 35vh;
    max-width: 100%; 
    height: auto;
    z-index: 1;
  }
}
