body {
  align-items: flex-start;
  overflow: auto;
}

.textfield {
  width: 90%;
  text-align: left;
  margin-top: 2%;
  padding: 2%;
  border-radius: 10px;
}
.textfield p {
  font-size: x-large;
  font-family: "TimesNewRoman";
  line-height: 1.7;
  word-spacing: 7%;
  letter-spacing: 0.7px;
  color: white;
}

#cut-text1 { 
  visibility: hidden;
  white-space: nowrap;
}
#dots1 {
  visibility: visible;
  margin: 0px;
}
#cut-text2 { 
  visibility: hidden;
  white-space: nowrap;
}
#dots2 {
  visibility: visible;
  margin: 0px;
}

.promo {
  width: 60%;
  text-align: center;
  margin-top: 5%;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.8);
  border-bottom-width: 1px;
}
.promo h1 {
  font-family: "TimesNewRoman";
  padding-bottom: 2%;
  font-weight: 200;
  word-spacing: 8%;
  letter-spacing: 1px;
  color: white;
}

.gallery {
margin-top: 1%;
}
link {
  color: #42a5f5;
}
link:hover {
  color: #0288d1;
}

#top-border {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.8);
  border-radius: 0px;
  border-top-width: 1px;
  padding-top: 4%;
}

.video-responsive {
  margin-top: 5%;
  margin-bottom: 3%;
  border-width: 1px;
}