body {
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
}

.textfield {
  width: 90%;
  text-align: left;
  margin-top: 3%;
  padding: 3%;
  border-radius: 10px;
}
.textfield p {
  font-family: "TimesNewRoman";
  line-height: 1.7;
  word-spacing: 7%;
  letter-spacing: 0.7px;
  color: white;
}

.grid-item {
  background-color: rgb(30, 30, 30);
  padding: 3%;
  border-radius: 10px;
}
.grid-item h2 {
  color: white;
}